@font-face {
  font-family: "Brand Font";
  src: url('https://s3-eu-west-1.amazonaws.com/storystream-misc/fonts/mclaren/GothamNarrow-Book.eot');
  src: url('https://s3-eu-west-1.amazonaws.com/storystream-misc/fonts/mclaren/GothamNarrow-Book.eot') format('embedded-opentype'), url('https://s3-eu-west-1.amazonaws.com/storystream-misc/fonts/mclaren/GothamNarrow-Book.woff') format('woff'), url('https://s3-eu-west-1.amazonaws.com/storystream-misc/fonts/mclaren/GothamNarrow-Book.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Brand Font Light";
  src: url('https://s3-eu-west-1.amazonaws.com/storystream-misc/fonts/mclaren/GothamNarrow-Light.otf');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Brand Font Medium";
  src: url('https://s3-eu-west-1.amazonaws.com/storystream-misc/fonts/mclaren/GothamNarrow-Medium.otf');
  font-weight: 300;
  font-style: normal;
}
* {
  font-family: "Brand Font";
}
.header-banner {
  margin-top: 40px;
  height: 108px;
  margin-bottom: 24px;
  text-align: center;
  width: 100%;
}
.header-banner .header-logo {
  background-image: url("https://diyjffp6v849u.cloudfront.net/9187e01c-0d2b-43dc-9bb9-dfc4c4cc7070.png?versionId=aLYa4wQza3YDqgNlg5KKT9jrcoOzLL_k");
  height: 80px;
  margin: auto;
  width: 200px;
}
@media only screen and (max-width: 768px) {
  .header-banner .header-logo {
    height: 40px;
  }
}
.header-banner h1 {
  font-family: "Brand Font Medium";
  font-size: 22px;
  font-weight: 300;
  margin-bottom: 80px;
}
.header-banner .header-message {
  margin: 0;
}
.header-banner .image {
  position: absolute;
  top: 34px;
  right: 32px;
  width: 168px;
}
@media only screen and (max-width: 1024px) {
  .header-banner .image {
    position: static;
  }
}
.main-content {
  border-radius: 8px;
  border: 1px solid #F4F4F4;
  box-shadow: 0px 0px 14px #F4F4F4;
  margin: auto;
  max-width: 420px;
  padding: 30px;
  text-align: center;
}
.main-content h2 {
  font-size: 36px;
  margin-top: 0;
}
.main-content p {
  font-size: 16px;
  margin-top: 0;
}
.main-content p.sub-header {
  font-size: 18px;
  text-decoration: underline;
}
.main-content .primary-message {
  background-color: #FFF5EB;
  border-radius: 6px;
  border: 2px solid #FD9D31;
  color: #FD9D31;
  margin: auto;
  padding: 16px;
  width: calc(100% - 80px);
}
.main-content .primary-message ul {
  padding: 0 0 0 20px;
}
.main-content .primary-message ul,
.main-content .primary-message ul li {
  text-align: left;
  line-height: 25px;
}
.main-content .redirect-link {
  color: #222222;
  display: block;
  font-size: 14px;
  margin: 16px 0;
  text-decoration: underline;
}
.main-content form {
  margin: 24px 0;
}
.main-content form p label {
  display: block;
  font-size: 14px;
  margin-left: 24px;
  text-align: left;
  text-transform: uppercase;
}
.main-content form p label[for="id_terms"] {
  float: left;
}
.main-content form p label[for="id_captcha"] {
  display: none;
}
.main-content form p input[type="text"],
.main-content form p input[type="email"],
.main-content form p input[type="password"] {
  border-radius: 4px;
  border: 1px solid #F4F4F4;
  border: none;
  -webkit-appearance: none;
  -webkit-box-shadow: 0px 0px 14px #F4F4F4;
  box-shadow: 0px 0px 14px #F4F4F4;
  font-size: 16px;
  height: 24px;
  margin: 12px 0;
  max-width: 360px;
  outline: none;
  padding: 8px;
  text-align: center;
  width: calc(100% - 42px);
}
.main-content form p input#id_terms {
  position: relative;
  top: -2px;
}
.main-content form .primary-button {
  background-color: #FD9D31;
  border: 1px solid #FD9D31;
  color: #FFFFFF;
  display: inline-block;
  font-size: 16px;
  height: 40px;
  padding: 8px 16px;
  text-transform: uppercase;
}
.main-content form ul {
  display: none;
  list-style-type: none;
  margin: auto;
  margin: 0;
  padding: 0;
  text-indent: 0;
  width: calc(100% - 80px);
}
.main-content form ul.errorlist {
  background-color: rgba(255, 0, 0, 0.1);
  border-radius: 6px;
  border: 2px solid red;
  color: red;
  display: inline-block;
  margin-bottom: 24px;
  margin: auto;
  padding: 16px;
  text-align: center;
}
.main-content form ul li {
  margin: 0;
  padding: 0;
  text-indent: 0;
  list-style-type: none;
}
.main-content form label[for="id_captcha"] {
  display: none;
}
.main-content form #id_captcha > div {
  margin: 0 auto;
}
.main-content .primary-button {
  background-color: #FD9D31;
  border: 1px solid #FD9D31;
  color: #FFFFFF;
  display: inline-block;
  font-size: 16px;
  padding: 8px 16px;
  text-decoration: none;
  text-transform: uppercase;
}
.main-content.left-align {
  text-align: left;
}
.main-content .center-align {
  text-align: center;
}
.inline-link {
  color: #FD9D31;
  text-decoration: none;
}
h3.logout-title {
  font-family: "Brand Font Medium", "Brand Font";
  font-size: 20px;
}
p.logout-message {
  font-family: "Brand Font Light", "Brand Font";
  border: 2px solid #f2f2f2;
  padding: 15px;
  background-color: #f9f9f9;
  font-size: 16px;
  border-radius: 6px;
  margin-bottom: 80px;
}
